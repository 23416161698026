import request from "./request"; //引入封装好的axios

const api ={
    // 把所有接口都在这里定义出来
    list:'/api/shop/index',//列表
    pay:'/api/pay/index',//支付方式
    setData:'/api/index/setData',//公共配置
    create:'/api/order/create',//下单
    paycreate:'/api/pay/create',//支付
    detail:'/api/order/detail',//查询订单
    generate:'/api/order/generateRandomString',//生成随机数
}

//商品列表
export function apilist(parameter){
    return request({
        url:api.list,
        method:"get",
        params:parameter
    })
}
//支付方式
export function apipay(parameter){
    return request({
        url:api.pay,
        method:"get",
        params:parameter
    })
}
//公共配置
export function setData(parameter){
    return request({
        url:api.setData,
        method:"get",
        params:parameter
    })
}
//下单
export function create(parameter){
    return request({
        url:api.create,
        method:"post",
        data:parameter
    })
}
//支付
export function paycreate(parameter){
    return request({
        url:api.paycreate,
        method:"post",
        data:parameter
    })
}
//查询订单
export function detail(parameter){
    return request({
        url:api.detail,
        method:"get",
        params:parameter
    })
}
//随机数
export function generate(parameter){
    return request({
        url:api.generate,
        method:"get",
        params:parameter
    })
}






// export function housegeng(parameter){
//     return request({
//         url:api.housegeng,
//         method:"POST",
//         data:parameter
//     })
// }
